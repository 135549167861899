@tailwind base;
@tailwind components;

@import './scss/header.scss';

@tailwind utilities;

.hide {
  display: none !important;
}

.loading {
  display: block;
  position: absolute;
  width: 100vw;
  height: 100vh;
  opacity: .5;
  left: 0;
  top: 0;
  background: black;
}

.skeleton {
  height: 30px;
}

.content-container {
  max-width: 960px;
  margin: 0 auto;
  font-family: sans-serif;
  color: white;
}

.photo {
  width: 300px;
  height: 250px;
  float: left; 
  margin-right: 14px;
  background: black;
}

.skeleton {
  background: black;
  position: relative;
  overflow: hidden;
  
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(270deg, black, rgba(20,20,20,1), black);
    transform: translate(-100%);
    animation: skeleton-loader 2s infinite;
  }
 
}

h1.skeleton {
  height: 40px;
}

@keyframes skeleton-loader {
  0% { transform: translate(-100%); }
  100% { transform: translate(100%); }
}