.header {
  background: white;
  @apply 
    w-full 
    shadow-md items-center;

    nav {
      ul {
        @apply 
        flex items-center mx-auto
        space-x-4;
      }

      a {
        @apply 
        text-gray-700
        hover:text-green-700;
      }

      .active {
        @apply 
        text-green-700;
      }
    }

    .nav-user {
      display: none;
    }
}